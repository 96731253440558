'use strict';

// jshint ignore: start
/* eslint-disable */

const Marionette = require('backbone.marionette'),
	$ = require('jquery'),
	_ = require('lodash'),
	//
	utils = require('../../utilities/utils'),
	//
	templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
	attributes: {
		class: 'preview-details scroll-y',
		'data-name': 'preview-details'
	},
	ui: {
		backToTop: '#back-to-top',
		scrollToSection: '.js-scroll-element',
		printPage: '.js-print-page',
		showMoreLess: '.js-article-study-findings-show-more-less'
	},
	events: {
		'scroll': 'onScroll',
		'click @ui.backToTop': 'backToTopClicked',
		'click @ui.scrollToSection': 'onScrollToClicked',
		'click @ui.printPage': 'onPrintPageClicked',
		'click @ui.showMoreLess': 'onShowMoreLessClicked'
	},
	tagName: 'div',
	getTemplate: function () {
		return this.template;
	},
	initialize: function (options) {
		this.template = options.template || templates.programDetails;
		this.showAllQuestions = !!options.showAllQuestions;

		this.prepareData();
	},
	onScroll: function (event) {
		$(event.currentTarget).scrollTop() > 50 ? $('#back-to-top').fadeIn() : $('#back-to-top').fadeOut();
	},
	onScrollToClicked: function(event) {
		const $elem = this.$('#' + event.currentTarget.dataset.tag + 'Header');
		this.$el.parent().animate({ scrollTop: 0 }, 200);   // If we can figure out why the parent "main" is scrolling (and prevent it), we won't need to do this.
		this.$el.animate({
			scrollTop: $elem.offset().top - this.$el.offset().top + this.$el.scrollTop()
		}, 500);
		$(event.currentTarget).blur();
	},
	backToTopClicked: function (event) {
		this.$el.parent().animate({ scrollTop: 0 }, 200);   // If we can figure out why the parent "main" is scrolling (and prevent it), we won't need to do this.
		this.$el.animate({ scrollTop: 0 }, 800);
		return false;
	},
	onPrintPageClicked: function() {
		window.print();
	},
	onShowMoreLessClicked: function(event) {
		var refId = $(event.currentTarget).data('show');
		if (this.$('.js-study-findings-' + refId).is(':visible')) {
			this.$('.js-study-findings-' + refId).hide(400, function() { $(event.currentTarget).html('Show More...') });
		} else {
			this.$('.js-study-findings-' + refId).show(400, function() { $(event.currentTarget).html('Show Less...') });
		}
	},
	// onDataReceived: function (data) {
	//     console.log('ProgramDetailsView.onDataReceived: ...');
	//     this.render();
	// },
	// onBeforeRender: function() {
	//     console.log('ProgramDetailsView.onBeforeRender: ...');
	//
	//     this.prepareData();
	// },
	onRender: function() {
		console.log('ProgramDetailsView.onRender: ...');
		var that = this;
		this.$el.find('tr.js-details-toa-section').on('click', that.toggleToASection);
		this.$el.find('tr.js-details-ar-section').on('click', that.toggleARSection);
		// if (app.compareIds) {
		//     this.$('.js-return-to-compare').attr('href', '#comparePrograms/' + app.compareIds);
		//     delete app.compareIds;
		// } else {
		//     this.$('.js-return-to-compare').hide();
		// }
		// this.$('.collapse.show').each(function () {
		//     $(this).prev('.card-header').find('.fa').removeClass('fa-chevron-down').addClass('fa-chevron-up');
		// });
		// this.$('.collapse').on('show.bs.collapse', function () {
		//     $(this).prev('.card-header').find('.fa-chevron-down').removeClass('fa-chevron-down').addClass('fa-chevron-up');
		// }).on('hide.bs.collapse', function () {
		//     $(this).prev('.card-header').find('.fa-chevron-up').removeClass('fa-chevron-up').addClass('fa-chevron-down');
		// });
		if (this.$('.js-program-details-logo').find('img').attr('src') == '') {
			this.$('.js-program-details-logo').hide();
		}
	},
	onDestroy: function() {
		// $(window).off('scroll');
		$('#back-to-top').unbind('click');
	},
	toggleToASection: function() {
		console.log('ProgramDetailsView.toggleToASection: ...');
		$(this).toggleClass('expanded');
		$(this).nextUntil('tr.js-details-toa-section').toggle();
	},
	toggleARSection: function() {
		console.log('ProgramDetailsView.toggleARSection: ...');
		$(this).toggleClass('expanded');
		$(this).nextUntil('tr.js-details-ar-section').toggle();
	},
	detailsPartialsMap: [
		{name:'_detailsBlockPartial', properties: {level2Value:'Program Overview'}},
		{name:'_detailsBlockListPartial', properties: {level2Value:'Program Components'}},

		{name:'_detailsBlockPartial', properties: {level2Value:'Program Development or Adaptations for Diverse Communities'}},

		{name:'_detailsBlockListPartial', properties: {level2Value:'Program Characteristics', level3Value:'Record-Keeping System or Tools', profileLabel:'Information tracked with record-keeping system or tools'}},

		{name:'_detailsLevelGroupListPartial', properties: {level4Value:'Characteristics of Persons with Dementia'}},
		{name:'_detailsLevelGroupListPartial', properties: {level4Value:'Characteristics of Caregivers'}},
		{name:'_detailsLevelGroupListPartial', properties: {level3Value:'Content and Format'}},

		{name:'_detailsBlockPartial', properties: {level3Value:'Conceptual or Theoretical Frameworks'}},
		{name:'_detailsBlockListPartial', properties: {level3Value:'Program Manuals'}},

		{name:'_detailsCheckmarkListPartial', properties: {level3Value:'User Characteristics'}},

		//{name:'_detailsLabeledRowPartial', properties: {level2Value:'Delivery Site Survey Information'}},
		{name:'_detailsLabeledRowPartial', properties: {level3Value:'Responses From Organizations Delivering the Program (Responses Displayed if 3 or More Surveys Were Completed)'}},

		{name:'_detailsLabeledRowPartial', properties: {level3Value:'Delivery History'}},
		{name:'_detailsLabeledRowPartial', properties: {level3Value:'Delivery Site Setting (Percent of Sites)'}},
		{name:'_detailsLabeledRowPartial', properties: {level3Value:'Description of Staff Delivering Program'}},
		{name:'_detailsLabeledRowPartial', properties: {level3Value:'Adaptations of the Program'}},

		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Languages other than English program delivered in (percent of sites)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Number of participants (average in past 12 months)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Persons with dementia characteristics (average percent in past 12 months)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Caregiver characteristics (average percent in past 12 months)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Eligibility criteria for participants (percent of sites; open-ended narrative responses)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Characteristics of participants targeted (percent of sites; open-ended narrative responses)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Types of changes or adaptions (percent of sites; open-ended narrative responses)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Field of study of delivery persons (percent of sites)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Type of degree of delivery persons (percent of sites)'}},

		//{name:'_detailsLabeledRowPartial', properties: {level4Value:'Delivery Site Setting (Average Percent of Sites)'}},

		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Perceived improvement rating (1=Strongly Disagree to 5=Strongly Agree)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Perceived decreased rating (1=Strongly Disagree to 5=Strongly Agree)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Success rating (1=Not at All Successful to 10=Very Successful)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Strategies for successfully obtaining resources and support (percent of sites; open-ended narrative responses)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Major or minor challenges with resources and support (percent of sites)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Success rating (1=Not at All Successful to 10=Very Successful)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Strategies for successful recruitment (percent of sites; open-ended narrative responses)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Major or minor recruitment challenges (percent of sites)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Satisfaction rating (1=Very Dissatisfied to 10=Very Satisfied)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Success rating (1=Not at All Successful to 10=Very Successful)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Major or minor financial challenges (percent of sites)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Funding sources (number of sites)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Funding sources (percent of sites)'}},
		{name:'_detailsLabeledRowPartial', properties: {level4Value:'Estimated percent of cost covered by funding source (average per site, if applicable)'}}
	],
	detailsLevelsPartialsMap: [
		{name:'_detailsTypesOfAssistanceTablePartial', level3Value:'Types of Assistance'},

		{name:'_detailsLevelGroupListPartial', level4Value:'Characteristics of Persons with Dementia'},
		{name:'_detailsLevelGroupListPartial', level4Value:'Characteristics of Caregivers'},

		{name:'_detailsLevelStudiesPartial', level2Value:'Study Characteristics'},
		{name:'_detailsLevelArticlesPartial', level2Value:'Study Findings'}
	],
	getDetailsLevelPartial: function(level, value) {
		var key = 'level' + level + 'Value',
			properties = {}; // IE doesn't like the {[key]:value} declairation.
		properties[key] = value; // assign value here
		var match = _.find(this.detailsLevelsPartialsMap, properties);
		//console.log('getDetailsLevelPartial: level=%s value=%s properties=%s match=%s', level, value, JSON.stringify(properties), JSON.stringify(match));
		return match ? match.name : undefined;
	},
	getResponseForConstruct: function(values, construct) {
		var response = '',
			question = _.find(values, {construct: construct});
		if (question && question.answers && question.answers.length) {
			response = question.answers[0].response;
		}
		return response;
	},
	getResponseForQuestion: function(values, tag) {
		var response = '',
			question = _.find(values, {tag: tag});
		if (question && question.answers && question.answers.length) {
			response = question.answers[0].response;
		}
		return response;
	},
	getResponsesForQuestions: function(values, tags) {
		var responses = [];
		_.each(tags, function(tag) {
			var response = '',
				question = _.find(values, {tag: tag});
			if (question && question.answers && question.answers.length) {
				response = question.answers[0].response;
			}
			responses.push(response);
		});
		return responses;
	},
	getResponseValueForQuestion: function(values, tag) {
		var responseValue = 0,
			question = _.find(values, {tag: tag});
		if (question && question.answers && question.answers.length) {
			responseValue = question.answers[0].responseValue;
		}
		return responseValue;
	},
	getResponseValuesForQuestions: function(values, tags) {
		var responseValues = [];
		_.each(tags, function(tag) {
			var responseValue = 0,
				question = _.find(values, {tag: tag});
			if (question && question.answers && question.answers.length) {
				responseValue = question.answers[0].responseValue;
			}
			responseValues.push(responseValue);
		});
		return responseValues;
	},
	getTypesOfAssistanceSectionData: function(section, values) {
		var data = [];
	},
	getTypesOfAssistanceData: function(values) {
		var that = this,
			q = _.first(values),
			data = {
				name: 'Types of assistance program provides:',
				tag: _.camelCase(q.level3Value),
				partial: '_detailsTypesOfAssistanceTablePartial',
				columnHeaders: ['Provided to:', 'Direct Assistance', 'Information', 'Training', 'Referring'],
				footnotes: [],
				sections: []
			},
			sections = _.groupBy(values, function(q) { return q.level4Value; });
		_.each(sections, function(sectionValues) {
			var q = _.first(sectionValues),
				providedTo = [],
				sectionData = {
					name: q.level4Value,
					tag: _.camelCase(q.level4Value),
					primarySecondary: 0,
					data: [],
					subsections: []
				},
				subsections = _.groupBy(sectionValues, function(q) { return q.level5Value; });

			if (!q.level4Value) { return; }

			// skip topmost level program overall assessments
			if (/(cg|pwd) receives any assistance/i.test(q.construct)) { return; }

			sectionData.primarySecondary = that.getResponseForConstruct(sectionValues, 'Program assists with TYPE OF ASSISTANCE (Yes- Primary, Yes- Secondary, No)');
			if (/^yes$/i.test(that.getResponseForQuestion(sectionValues, 'pwd'))) {
				providedTo.push('PWD');
			}
			if (/^yes$/i.test(that.getResponseForQuestion(sectionValues, 'cg'))) {
				providedTo.push('CG');
			}
			sectionData.data.push(providedTo.join(','));
			sectionData.data = sectionData.data.concat(that.getResponsesForQuestions(sectionValues, ['directAssistance','information','training','referring']));

			_.each(subsections, function(subsectionValues) {
				var q = _.first(subsectionValues),
					providedTo = [],
					subsectionData = {
						name: q.level5Value,
						tag: _.camelCase(q.level5Value),
						data: []
					};

				if (!q.level5Value) { return; }

				if (/^yes$/i.test(that.getResponseForQuestion(subsectionValues, 'pwd'))) {
					providedTo.push('PWD');
				}
				if (/^yes$/i.test(that.getResponseForQuestion(subsectionValues, 'cg'))) {
					providedTo.push('CG');
				}
				subsectionData.data.push(providedTo.join(','));
				subsectionData.data = subsectionData.data.concat(that.getResponsesForQuestions(subsectionValues, ['directAssistance','information','training','referring']));

				sectionData.subsections.push(subsectionData);
			});
			data.sections.push(sectionData);
		});

		data.footnotes[0] = {key:'*', value:'Primary type of assistance delivered by program'};
		/*
        if (_.find(data.sections, {primarySecondary: 1})) {
            data.footnotes[1] = {key:'&dagger;', value:'Primary type of assistance delivered by program'};
        }
        if (_.find(data.sections, {primarySecondary: 2})) {
            data.footnotes[2] = {key:'&dagger;&dagger;', value:'Secondary type of assistance delivered by program'};
        }
        */

		return data;
	},
	prepareData: function() {
		var that = this,
			program = this.model.get('program') || {},
			questions = this.model.get('questions'),
			answers = this.model.get('answers'),
			responses = this.model.get('responses'),
			questionLookup = _.keyBy(questions, 'id'),
			articles,
			studies,
			arOutcomes,
			arStudies,
			level1,
			levels = [],
			addQuestions = function(level, questions) {
				// console.log('addQuestions: ...');
				_.each(questions, function(q) {
					var name = q.profileLabel,
						item = {name: name, tag: q.tag, question: q};
					level.push(item);
				});
			},
			getSummaryFieldAnswers = function(properties, clear) {
				var answers = [],
					provides,
					briefSide = properties.brief && properties.brief === 'side',
					items = _.filter(questions, properties);
				_.each(items, function(item) {
					if (item.answers && item.answers.length) {
						provides = briefSide && item.profileLabel === "Provides:" ? ["Provides:"] : undefined;
						_.each(item.answers, function(answer) {
							// For open-ended questions, we want the responseValue, since it the the text that was entered.
							// For all other questions, we want the "response" since the "responseValue" is often an index and "response" is the text we want to display.
							var a = (answer.cbQuestionType === 'openEnded') ? answer.responseValue : answer.response;
							if (a.length) {
								if (answer.response && answer.response == "Website") {
									var urls = a.split(/\s+/),
										urlLinks = [];
									_.each(urls, function(u) {
										var url = u.match(/^https?:/) ? u : 'http://' + u;
										urlLinks.push('<a href="' + url + '" target="_blank">' + u + '</a>');
									});
									answers.push(urlLinks.join('<br/>'));
								} else if (answer.response && answer.response == "Email") {
									var email = a.split(';'); // some responses have multiple email addresses
									_.each(email, function(emailaddress) {
										var e = emailaddress.replace(/\s/g, ''); // remove spaces
												answers.push('<a href="mailto:' + e + '?cc=bpc@caregiver.org">' + e + '</a>');
									});
								} else {
									if (provides) {
										provides.push(a)
									}
									else {
										answers.push(a);
									}
								}
							}
						});
						if (provides && provides.length > 1) {
							answers.pop(); // pop last <br/>
							answers.push([provides[0], provides.slice(1).join(',')].join(' '));
						}
						if (briefSide) {
							answers.push('<br/>');
						}
						if (clear) {
							item.answers = [];
						}
					}
				});
				return answers;
			},
			parseArticleReference = function(reference) {
				var patterns = [
						// 4 parts: authors/year, title, journal reference, url
						/^(.+\(\d{4}\)[.]{0,1}|.+\(n\.d\.\)[.]{0,1})\s*(["]*[^.]+[.?]["]*)\s*(.+[.]{0,1})\s*(http.+)$/,
						/^(.+\(\d{4}\)[.]{0,1}|.+\(n\.d\.\)[.]{0,1})\s*(["]*[^.]+[.?]["]*)\s*(.+\.)\s*Retrieved from (http.+)$/,

						// 4 parts: authors/year, title, journal reference, doi:
						/^(.+\(\d{4}\)[.]{0,1}|.+\(n\.d\.\)[.]{0,1})\s*(["]*[^.]+[.?]["]*)\s*(.+\.)\s*(doi:.+)$/,

						// 3 parts: authors/year, title, journal reference
						/^(.+\(\d{4}\)[.]{0,1}|.+\(n\.d\.\)[.]{0,1})\s*(["]*[^.]+[.?]["]*)\s*(.+\.)$/,

						// 3 parts: authors, title, long journal reference
						/^([^.]+\.)\s*([^.]+[.?])\s*(.+[.]*)$/
					],
					parts;
				_.find(patterns, function(pattern) {
					parts = reference.match(pattern);
					return parts && parts.length > 2;
				});
				if (parts) { parts.shift(); }
				return parts;
			},
			getArticleStudySections = function(properties) {
				var sections = [],
					_questions = _.filter(questions, properties),
					_questionsLookup = _.keyBy(_questions, 'id'),
					_answers = _.filter(answers, function(answer) {
						return !!_questionsLookup[answer.questionId] && !/^yes|no$/i.test(answer.response);
					}),
					_sectionIds = _.uniq(_.map(_answers, 'programDataSetId'));
				_.each(_sectionIds, function(id) {
					var section = {},
						referenceId = 0,
						studyId = 0,
						sectionName = '',
						sectionNameParts = {},
						articleReferenceParts = ['authors','title','journal','url'],
						sectionQuestions = JSON.parse(JSON.stringify(_questions)),
						sectionQuestionsLookup = _.keyBy(sectionQuestions, 'id'),
						sectionAnswers = _.filter(_answers, function(answer) {
							return answer.programDataSetId === id;
						}),
						L4subsectionQuestions = [],
						L5subsectionQuestions = [];

					_.each(sectionAnswers, function(answer) {
						var sq = sectionQuestionsLookup[answer.questionId];
						if (sq) {
							if (/referenceId/ig.test(sq.tag)) {
								referenceId = answer.responseValue;
							}
							else if (/studyId/ig.test(sq.tag)) {
								studyId = answer.responseValue;
							}
							else if (/studyName/ig.test(sq.tag)) {
								sectionName = answer.responseValue;
							}
							else if (/articleReference/ig.test(sq.tag)) {
								sectionName = answer.responseValue;
								var parts = parseArticleReference(sectionName);
								if (parts && parts.length > 1) {
									_.each(parts, function(part, index) {
										sectionNameParts[articleReferenceParts[index]] = part;
									});
								}
							}
							else {
								sq.answers = sq.answers || [];
								sq.answers.push(answer);
							}
						}
					});

					section = _.extend({sectionId: id, studyId: studyId, referenceId: referenceId, sectionName: sectionName, L4subsections: []}, sectionNameParts);

					sectionQuestions = _.reject(sectionQuestions, function(sq) { return !sq.answers || !sq.answers.length; });
					L4subsectionQuestions = _.groupBy(sectionQuestions, function(q) { return q.level4Value; });
					_.each(L4subsectionQuestions, function(_L4questions, L4subsectionName) {
						var L4subsection = {
							name: L4subsectionName,
							L5subsections: []
						}
						L5subsectionQuestions = _.groupBy(_L4questions, function(q) { return q.level5Value; });
						_.each(L5subsectionQuestions, function(_questions, L5subsectionName) {
							var L5subsection = {
								name: L5subsectionName,
								questions: _questions
							}
							L4subsection.L5subsections.push(L5subsection);
						});
						section.L4subsections.push(L4subsection);
					});

					sections.push(section);
				});
				return _.sortBy(sections, 'studyId');
			},
			getArticleReferencesForStudy = function(studyId, _studyFindingsPerArticle) {
				var articlesProperties = {
						level1Value:'Research Evidence', level2Value:'Study Findings'
					},
					articleReferenceParts = ['authors','title','journal','url'],
					_questions = _.filter(questions, articlesProperties),
					_questionsLookup = _.keyBy(_questions, 'id'),
					_answers = _.filter(answers, function(answer) {
						return !!_questionsLookup[answer.questionId];
					}),
					pdsIdsForStudy = _.map(_.filter(_answers, {responseCode: 'studyid', responseValue: studyId}), 'programDataSetId'),
					rawReferences = _.filter(_answers, {responseCode: 'reference'}),
					references = [];

				rawReferences = _.filter(rawReferences, function(r) { return _.includes(pdsIdsForStudy, r.programDataSetId); });

				_.each(rawReferences, function(r) {
					var parts = parseArticleReference(r.responseValue),
						pdsId = r.programDataSetId,
						refidAnswer = _.find(_answers, {responseCode: 'referenceid', programDataSetId: pdsId}),
						reference = {referenceId: refidAnswer.responseValue},
						studyFindings = {};
					if (parts && parts.length > 1) {
						_.each(parts, function(part, index) {
							reference[articleReferenceParts[index]] = part;
						});
						if (_studyFindingsPerArticle) {
							studyFindings = _.find(articles, {referenceId: reference.referenceId});
							if (studyFindings) {
								reference.studyFindings = studyFindings.L4subsections;
							}
						}
						references.push(reference);
					}
				});

				return references;
			},
			getArticleReviewStudiesSection = function(_studyFindingsPerArticle) {
				var _q = utils.getResearchCategoryQuestions(questions, answers),
					_questions = _q.q_questions,
					_questionsLookup = _q.q_questionsLookup,
					_answers = _q.q_answers,
					_qpwd = _q.q_qpwd,
					_qcg = _q.q_qcg,
					_qsource = _q.q_qsource,
					_qfunders = _q.q_qfunders,
					_qdates = _q.q_qdates,
					_nonStudyQuestionsLookup = _q.q_nonStudyQuestionsLookup,
					_qstudy = _q.q_qstudy,
					studyNames = _.filter(_answers, function(a) { return a.responseCode === 'studyname'; }),
					studyNamesLookup = _.keyBy(studyNames, 'programDataSetId'),
					studies = _(_answers).chain()
						.filter(function(a) { return a.responseCode === 'studyid'; })
						.sortBy('responseValue', function(n) { return +n; })
						.sortBy('programDataSetId')
						.value(),
					studyIds = _.uniq(_.map(studies, 'responseValue')).sort(function(a,b){ return +a - +b; }),
					removeStudies = [],
					pdsIdByStudyId = [],
					programAdaptationsQuestion = {},
					formatStudyAnswers = function(a, programAdaptatonsQuestionId) {
						var _answers = JSON.parse(JSON.stringify(a)),
							checkAnswers = [];
						// remove yes, no, other answers
						_answers = _.reject(_answers, function(a) {
							return /yes|no|other/i.test(a.response);
						});
						// special formatting of certain answers (TODO: find better way of doing this)
						_.each(_answers, function(a) {
							switch (a.responseCode) {
								case 'q1b': a.response += ' randomization'; break;
								case 'q6b': a.responseValue += ' Persons with dementia'; break;
								case 'q7b': a.responseValue += ' Caregivers'; break;
							}
						});

						// if Number Data Points (q1e) and Study Measure Points (q1f)...
						checkAnswers = _.filter(_answers, function(a) { return /^q1e|q1f$/.test(a.responseCode); });
						if (checkAnswers && checkAnswers.length === 2) {
							checkAnswers[0].responseValue += ' data points: ' + checkAnswers[1].responseValue;
							_answers = _.reject(_answers, function(a) { return a.responseCode === checkAnswers[1].responseCode; });
						}
						// if no Program Adapations answers (q2a1-q2a14)...
						if (!_.find(_answers, function(a) { return /^q2a([1-9]{1}|1[01234]{1})$/.test(a.responseCode); })) {
							_answers.push({questionId: programAdaptatonsQuestionId, responseValue:'No major differences between the study and the original program'});
						}
						return _answers;
					},
					mergeQuestionsAnswers = function(q, a) {
						var _questions = JSON.parse(JSON.stringify(q)),
							_answers = JSON.parse(JSON.stringify(a));
						_.each(_questions, function(q) {
							q.answers = _.filter(_answers, function(a) {
								return q.id === a.questionId;
							})
						});
						_questions = _.filter(_questions, function(q) {
							return q.answers && q.answers.length; // only keep questions with answers
						});
						return _questions;
					},
					q = _.first(_questions),
					articleReviewStudies = {
						name: q.level2Value,
						tag: _.camelCase(q.level2Value),
						partial: '_detailsArticleReviewStudiesLayoutPartial',
						sections: []
					};

				_.each(studyIds, function(studyId) {
					var _studies = _.filter(studies, function(study) { return study.responseValue === studyId; }),
						pdsIds = _.map(_studies, 'programDataSetId'),
						answer = _.find(_answers, function(answer) { return _.includes(pdsIds, answer.programDataSetId); });
					if (answer) {
						pdsIdByStudyId.push({studyId: studyId, pdsId: answer.programDataSetId}); // we'll need this below so build it up now
					}
					else {
						removeStudies.push(studyId);
					}
				});
				studyIds = _.reject(studyIds, function(id) { return _.includes(removeStudies, id); });
				pdsIdByStudyId = _.keyBy(pdsIdByStudyId, 'studyId');
				programAdaptationsQuestion = _.find(_questions, {level1Value: 'Research Evidence', level2Value: 'Study Characteristics', level3Value: 'Differences', profileLabel: 'Program Adaptations'});

				// eliminate studyId and studyName so they don't show up repeated in study section as answers
				_answers = _.reject(_answers, function(a) { return /studyid|studyname/i.test(a.responseCode); });

				_.each(studyIds, function(studyId) {
					var pdsId = pdsIdByStudyId[studyId].pdsId,
						studyName = (studyNamesLookup[pdsId]) ? studyNamesLookup[pdsId].responseValue : '',
						articles = getArticleReferencesForStudy(studyId, _studyFindingsPerArticle),
						studyAnswers = formatStudyAnswers(_.filter(_answers, {programDataSetId: pdsId}), programAdaptationsQuestion.id),
						_study = {
							studyId: studyId,
							studyName: studyName,
							questions: mergeQuestionsAnswers(_qstudy,studyAnswers),
							subsections: [
								{name:'Eligibility Criteria for Study Inclusion of Persons With Dementia', questions: mergeQuestionsAnswers(_qpwd, studyAnswers)},
								{name:'Eligibility Criteria for Study Inclusion of Caregivers', questions: mergeQuestionsAnswers(_qcg, studyAnswers)},
								{name:'Source of data', questions: mergeQuestionsAnswers(_qsource, studyAnswers)},
								{name:'Funded by', questions: mergeQuestionsAnswers(_qfunders, studyAnswers)},
								{name:'Study Dates', questions: mergeQuestionsAnswers(_qdates, studyAnswers)},
								{name:'Articles', references: articles}
							]
						};

					articleReviewStudies.sections.push(_study);
				});

				return articleReviewStudies;
			},
			getArticleReviewOutcomesSection = function() {
				var articlesProperties = {
						level1Value:'Research Evidence',
						level2Value:'Study Findings'
					},
					outcomesProperties = {
						level1Value:'Research Evidence',
						level2Value:'Study Findings',
						level3Value:'Program Impact and Outcomes',
						profileLabel:'Results'
					},
					subheadersProperties = {
						level1Value:'Research Evidence',
						level2Value:'Study Findings',
						level3Value:'Program Impact and Outcomes',
						profileLabel:'Data type used for result'
					},
					_questions = _.filter(questions, articlesProperties),
					_questionsLookup = _.keyBy(_questions, 'id'),
					_answers = _.filter(answers, function(answer) {
						return !!_questionsLookup[answer.questionId];
					}),
					_answersLookup = [],
					studies = _(_answers).chain()
						.filter(function(a) { return a.responseCode === 'studyid'; })
						.sortBy('responseValue', function(n) { return +n; })
						.sortBy('programDataSetId')
						.value(),
					studyIds = _.uniq(_.map(studies, 'responseValue')).sort(function(a,b){ return +a - +b; }),
					removeStudies = [],
					pdsIdsByStudyId = [],
					q = _.first(_questions),
					outcomes = {
						name: q.level3Value,
						tag: _.camelCase(q.level3Value),
						partial: '_detailsArticleReviewOutcomesTablePartial',
						columnHeaders: ['',''],
						columnSubheaders: ['',''],
						footnotes: [],
						sections: []
					},
					rankedResults = ['+','-','*',''], // Beneficial, Adverse, No effect, no answer
					sections = [];

				// reset local vars to extract the study column subheaders captions
				_questions = _.filter(questions, subheadersProperties);
				_questionsLookup = _.keyBy(_questions, 'id');
				_answers = _.filter(answers, function(answer) {
					return !!_questionsLookup[answer.questionId];
				});
				_.each(studyIds, function(studyId) {
					var _studies = _.filter(studies, function(study) { return study.responseValue === studyId; }),
						pdsIds = _.map(_studies, 'programDataSetId'),
						answer = _.find(_answers, function(answer) { return _.includes(pdsIds, answer.programDataSetId); });
					if (answer) {
						pdsIdsByStudyId.push({studyId: studyId, pdsIds: pdsIds}); // we'll need this below so build it up now
						outcomes.columnHeaders.push('Study&nbsp;'+studyId);
						outcomes.columnSubheaders.push(answer.response);
					}
					else {
						removeStudies.push(studyId);
					}
				});
				studyIds = _.reject(studyIds, function(id) { return _.includes(removeStudies, id); });
				pdsIdsByStudyId = _.keyBy(pdsIdsByStudyId, 'studyId');

				// reset local vars to process just the outcomes data
				_questions = _.filter(questions, outcomesProperties);
				_questionsLookup = _.keyBy(_questions, 'id');
				_answers = _.filter(answers, function(answer) {
					return !!_questionsLookup[answer.questionId] &&
						!/[above|below|no]\s*benchmark/i.test(answer.response); // exclude for now, per Julie 10/23/2019 email
				});
				_answersLookup = _.keyBy(_answers, 'questionId');
				// rinse and repeat...exclude questions not represented in the answers,
				// this seems kind of messy but should be temporary until we can re-include benchmark q+a
				// and as an added benefit removes all rows with no answers at all
				_questions = _.filter(_questions, function(question) {
					return !!_answersLookup[question.id];
				});
				// reindex questions for use below
				_questionsLookup = _.keyBy(_questions, 'id');
				sections = _.groupBy(_questions, function(q) { return q.level4Value; });

				_.each(sections, function(section, sectionName) {
					var sectionData = {
							name: sectionName,
							tag: _.camelCase(sectionName),
							data: [sectionName].concat(_.map(studyIds, function(id) { return ''; })),
							subsections: []
						},
						subsections = _.groupBy(section, function(q) { return q.level5Value; });

					_.each(subsections, function(subsection, subsectionName) {
						var subsectionLookup = _.keyBy(subsection, 'id'),
							subsectionAnswers = _.filter(answers, function(answer) {
								return !!subsectionLookup[answer.questionId];
							}),
							subsectionData = {
								name: subsectionName,
								tag: _.camelCase(subsectionName),
								data: [subsectionName],
							};

						_.each(studyIds, function(studyId, index) {
							var pdsIds = pdsIdsByStudyId[studyId].pdsIds,
								answers = _.filter(subsectionAnswers, function(a) { return _.includes(pdsIds, a.programDataSetId); }),
								responses = _.map(answers, 'response'),
								result = _.includes(responses, 'Beneficial') ? '+' :
									_.includes(responses, 'Adverse') ? '-' :
										_.includes(responses, 'No effect') ? '*' : '';

							subsectionData.data.push(result);

							// update sectionData result as needed
							if (_.indexOf(rankedResults, sectionData.data[index+1]) > _.indexOf(rankedResults, result)) {
								sectionData.data[index+1] = result;
							}
						});

						sectionData.subsections.push(subsectionData);
					});

					outcomes.sections.push(sectionData);
				});

				outcomes.footnotes.push({key:'+', value:'Beneficial'});
				outcomes.footnotes.push({key:'*', value:'No effect'});
				outcomes.footnotes.push({key:'-', value:'Adverse'});

				return outcomes;
			},
			padDeliverySiteQuestionsAnswers = function() {
				var properties = [
						{responseCode: 'numelig', cbQuestionType: 'exactNumber', responseValue: '0', properties: {level3Value: 'Responses From Organizations Delivering the Program (Responses Displayed if 3 or More Surveys Were Completed)', construct: 'Delivery site survey number eligible'}},
						{responseCode: 'numcomp', cbQuestionType: 'exactNumber', responseValue: '0', properties: {level3Value: 'Responses From Organizations Delivering the Program (Responses Displayed if 3 or More Surveys Were Completed)', construct: 'Delivery site survey number completed'}},
						{responseCode: 'resprate', cbQuestionType: 'meanPercent', responseValue: '0.0', properties: {level3Value: 'Responses From Organizations Delivering the Program (Responses Displayed if 3 or More Surveys Were Completed)', construct: 'Delivery site response rate'}}
					],
					addAnswer = function(q, p) {
						if (q && (!q.answers || !q.answers.length)) {
							q.answers = q.answers || [];
							q.answers.push({isText: true, questionId: q.id, cbQuestionType: p.cbQuestionType, responseId: p.responseId, responseValue: p.responseValue});
						}
					},
					responsesLookup = _.keyBy(responses, 'responseCode');
				_.each(properties, function(p) {
					var response = responsesLookup[p.responseCode];
					if (response) {
						addAnswer(_.find(questions, p.properties), _.extend({responseId: response.id}, p));
					}
				});
			};

		console.log('ProgramDetailsView.prepareData: ...');

		// map questions to details display template partials
		_.each(questions, function(q) {
			q.partial = '_detailsDefaultPartial';
		});
		_.each(this.detailsPartialsMap, function(partial) {
			var matches = _.filter(questions, partial.properties);
			_.each(matches, function(match) {
				var q = questionLookup[match.id];
				q.partial = partial.name;
			});
		});

		// remove answers with no answer
		answers = _.reject(answers, function(answer) {
			return !answer.responseValue && !answer.response;
		});

		// special handling for Research Evidence subsections Outcomes, Study Characteristics, and Study Findings
		arOutcomes = getArticleReviewOutcomesSection();

		articles = getArticleStudySections({level1Value:'Research Evidence', level2Value:'Study Findings'});
		arStudies = getArticleReviewStudiesSection(articles);

		// map answers to questions
		_.each(answers, function (answer) {
			var q = questionLookup[answer.questionId];
			if (q) {
				q.answers = q.answers || [];
				q.answers.push(answer);
			}
		});

		// remove Target Populations questions with Yes/No answers leaving only optional text
		_.each(_.filter(questions, {level1Value:'Program Information', level2Value:'Program Overview', level3Value:'Target Populations'}), function(question) {
			question.answers = _.reject(question.answers, function(answer) {
				return /^yes|no$/i.test(answer.response);
			});
		});

		// remove L2 = Program Characteristics questions with Other answers leaving only any optional text
		_.each(_.filter(questions, {level1Value:'Program Information', level2Value:'Program Characteristics'}), function(question) {
			question.answers = _.reject(question.answers, function(answer) {
				return /^other$/i.test(answer.response);
			});
		});

		// remove Staffing questions with 'Other Disciplines' answers leaving only any optional text
		_.each(_.filter(questions, {level1Value:'Program Information', level2Value:'Program Characteristics', level3Value: 'Staffing'}), function(question) {
			question.answers = _.reject(question.answers, function(answer) {
				return /^other discipline /i.test(answer.response);
			});
		});

		// pluck out summary fields
		program.programName = getSummaryFieldAnswers({brief:'yes',level1Value:'Program Information',level2Value:'Program Overview',level3Value:'Program Name',profileLabel:'Program Name'}, true);
		program.programAKA = getSummaryFieldAnswers({brief:'yes',level1Value:'Program Information',level2Value:'Program Overview',level3Value:'Also known as',profileLabel:'Also known as'}, true);
		program.description = getSummaryFieldAnswers({brief:'yes',level1Value:'Program Information',level2Value:'Program Overview',level3Value:'Brief Program Summary',profileLabel:'Brief Program Description'}, true);
		program.deliveryPerson = getSummaryFieldAnswers({brief:'yes',profileLabel:'Type of delivery person'}, true);
		program.languages = getSummaryFieldAnswers({brief:'yes',level1Value:'Program Information',level2Value:'Program Overview',level3Value:'Languages'}, true);
		program.sessionLength = getSummaryFieldAnswers({brief:'yes',profileLabel:'Session length'}, true);
		program.programLength = [];
		const programDays = getSummaryFieldAnswers({brief: '',construct:'Time to Receive Full Program',tag:'days'}, true);
		if (programDays && programDays.length) {
			const units = (programDays[0] === '1') ? 'day' : 'days';
			programDays.push(units);
			program.programLength.push(programDays.join(' '));
		}
		const programWeeks = getSummaryFieldAnswers({brief: '',construct:'Time to Receive Full Program',tag:'weeks'}, true);
		if (programWeeks && programWeeks.length) {
			const units = (programWeeks[0] === '1') ? 'week' : 'weeks';
			programWeeks.push(units);
			program.programLength.push(programWeeks.join(' '));
		}
		const programMonths = getSummaryFieldAnswers({brief: '',construct:'Time to Receive Full Program',tag:'months'}, true);
		if (programMonths && programMonths.length) {
			const units = (programMonths[0] === '1') ? 'month' : 'months';
			programMonths.push(units);
			program.programLength.push(programMonths.join(' '));
		}
		const programOther = getSummaryFieldAnswers({brief: '',construct:'Time to Receive Full Program',tag:'other'}, true);
		if (programOther && programOther.length) {
			program.programLength.push(programOther.join(' '));
		}
		program.individualFormat = getSummaryFieldAnswers({brief:'yes',profileLabel: 'One-on-one format'}, true);
		program.groupFormat = getSummaryFieldAnswers({brief:'yes',profileLabel: 'Group format'}, true);
		program.individualOrGroupFormat = getSummaryFieldAnswers({brief:'yes',profileLabel: 'One-on-one or group format'}, true);

		program.developers = getSummaryFieldAnswers({brief:'side',level1Value:'Program Information',level2Value:'Program Overview',level3Value:'Developer'}, true);
		program.contacts = getSummaryFieldAnswers({brief:'side',level1Value:'Program Information',level2Value:'Program Overview',level3Value:'Contact for Program Information'}, true);

		// pad Delivery Site "Responses From Organizations Delivering the Program" section with first 3 questions if missing -- special request so this section always present
		padDeliverySiteQuestionsAnswers();

		// identify text answers and format as needed
		_.each(questions, function(question) {
			_.each(question.answers, function(answer) {

				if (answer.isText) {
					// Answer has already been processed and possibly formatted. Do nothing.
				} else if (/^textarea$/gi.test(answer.cbQuestionType)) {
					answer.isText = true;
				} else if (+answer.overrideQuestionTypeId === 5) {
					answer.isText = true;
				}
				else if (/exactAmount/gi.test(answer.cbQuestionType)) {
					if (/^\d*$/.test(answer.responseValue)) { answer.responseValue = '$' + Number(answer.responseValue).toLocaleString(); }
					answer.isText = true;
				}
				else if (/^percent$/gi.test(answer.cbQuestionType)) {
					if (answer.responseValue) { answer.responseValue = parseFloat(answer.responseValue).toFixed(0) + '%'; }
					answer.isText = true;
				}
				else if (/^meanPercent$/gi.test(answer.cbQuestionType)) {
					if (answer.responseValue) { answer.responseValue = (parseFloat(answer.responseValue)*100).toFixed(1) + '%'; }
					answer.isText = true;
				}
				else if (/exactNumber/gi.test(answer.cbQuestionType)) {
					if (/\./g.test(answer.responseValue)) { answer.responseValue = parseFloat(answer.responseValue).toFixed(1); }
					answer.isText = true;
				}
				else if (/open|exact/gi.test(answer.cbQuestionType)) {
					answer.isText = true;
				}
			});

			// remove text answers with no responseValue
			question.answers = _.reject(question.answers, function(answer) {
				return answer.isText && !answer.responseValue;
			});
		});

		if (!this.showAllQuestions) {
			// remove questions with no answers
			questions = _.reject(questions, function(question) { return !question.answers || !question.answers.length; });
		}

		// marshall questions into a numLevel1 through numLevel5 hierarchy of sections and questions for display
		level1 = _.groupBy(questions, function(q) { return q.numLevel1; });
		// console.log('level1.length=%s', _.size(level1));
		_.each(level1, function(values) {
			var q = _.first(values);
			if (!q.numLevel2) {
				addQuestions(levels, values);
			} else {
				var name = q.level1Value,
					level1Item = {name: name, tag: _.camelCase(name), sublevels: []},
					level2 = _.groupBy(values, function(q) { return q.numLevel2; });
				// console.log('level1Item name=%s tag=%s level2.length=%s', level1Item.name, level1Item.tag, _.size(level2));
				_.each(level2, function(values) {
					var q = _.first(values);
					if (/Study Characteristics|Study Findings/i.test(q.level2Value)) {
						// do nothing, skip these, since we are populating display of these items
						// separately with Outcomes table and Study Characteristics special layout
						console.log('SKIPPING %s...', q.level2Value);
					}
					else if (!q.numLevel3) {
						addQuestions(level1Item.sublevels, values);
					} else {
						var name = q.level2Value,
							partial = that.getDetailsLevelPartial(2, name),
							level2Item = {name: name, tag: _.camelCase(name), partial: partial, sublevels: []},
							level3 = _.groupBy(values, function(q) { return q.numLevel3; });
						// console.log('level2Item name=%s tag=%s level3.length=%s', level2Item.name, level2Item.tag, _.size(level3));
						_.each(level3, function(values) {
							var q = _.first(values);
							if (!q.numLevel4) {
								addQuestions(level2Item.sublevels, values);
							} else {
								var name = q.level3Value,
									partial = that.getDetailsLevelPartial(3, name),
									level3Item = {name: name, tag: _.camelCase(name), partial: partial, sublevels: []},
									level4 = _.groupBy(values, function(q) { return q.numLevel4; });
								// console.log('level3Item name=%s tag=%s level4.length=%s', level3Item.name, level3Item.tag, _.size(level4));
								if (partial && partial === '_detailsTypesOfAssistanceTablePartial') {
									var data = that.getTypesOfAssistanceData(values);
									level2Item.sublevels.push(data);
								}
								else {
									_.each(level4, function(values) {
										var q = _.first(values);
										if (!q.numLevel5) {
											addQuestions(level3Item.sublevels, values);
										} else {
											var name = q.level4Value,
												partial = that.getDetailsLevelPartial(4, name),
												level4Item = {name: name, tag: _.camelCase(name), partial: partial, sublevels: []},
												level5 = _.groupBy(values, function(q) { return q.level5Value; });
											if (level3Item.tag === 'typesOfAssistance') {
												var primarySecondary = that.getResponseForQuestion(values, 'primaryOrSecondary');
												if (primarySecondary) {
													level4Item = _.extend(level4Item, {primarySecondary: primarySecondary.toLowerCase()});
												}
												// console.log('ToA level4Item=%s primarySecondary=%s', JSON.stringify(level4Item), primarySecondary);
											}
											// console.log('level4Item name=%s tag=%s level5.length=%s', level4Item.name, level4Item.tag, _.size(level5));
											_.each(level5, function(values) {
												var q = _.first(values),
													name = q.level5Value,
													partial = that.getDetailsLevelPartial(5, name),
													level5Item = {name: name, tag: _.camelCase(name), partial: partial, sublevels: []};
												// console.log('level5Item name=%s tag=%s values.length=%s', level5Item.name, level5Item.tag, _.size(values));
												if (level3Item.tag === 'typesOfAssistance') {
													var items = ['pwd','cg','directAssistance','information','training','referring'],
														responses = {};
													_.each(items, function(item) {
														var response = that.getResponseForQuestion(values, item);
														if (response) {
															responses[item] = response;
														}
													});
													level5Item = _.extend(level5Item, responses);
													// console.log('ToA level5Item=%s', JSON.stringify(level5Item));
												}
												addQuestions(level4Item.sublevels, values);
												level4Item.sublevels.push(level5Item);
											});
											level3Item.sublevels.push(level4Item);
										}
									});
									level2Item.sublevels.push(level3Item);
								}
							}
						});
						level1Item.sublevels.push(level2Item);
					}
				});
				levels.push(level1Item);
			}
		});

		// plug in articles and studies
		if (articles.length) {
			// PMQ20191211 - no longer showing Study Findings section, instead,
			// study findings by article added to the Study Characteristics Study subsections
			/*
    		var artLoc = _.find(_.find(levels, {name:'Research Evidence'}).sublevels, {name:'Study Findings'});
            artLoc = _.extend(artLoc, {sections: articles});
            */
			// add to top level also to populate article references list top to left of details
			program.articles = articles;
		}

		if (arStudies.sections.length) {
			var scLoc = _.find(levels, {name:'Research Evidence'});
			scLoc && scLoc.sublevels.unshift(arStudies);
		}

		if (arOutcomes.sections.length) {
			var ocLoc = _.find(levels, {name:'Research Evidence'});
			ocLoc && ocLoc.sublevels.unshift(arOutcomes);
		}

		program.levels = levels;
		this.model.set('program', program);
	}
});
